<template>
    <div class="payment-mode-selector">
        <div class="vx-row" v-if="firstOption==='sepa_debit'">
            <div class="vx-col sm:tw-w-1/2 tw-w-full">
                <div
                    :class="[
                        'item',
                        isModeSelected('sepa_debit') ? 'item-selected' : '',
                        disabled ? 'item-disabled' : ''
                    ]"
                    @click="onModeChange('sepa_debit')"
                    data-cy="sepa-mode-tab"
                >
                    <!-- <div class="item-circle"></div> -->
                    <img class="item-icon" :src="getModeIcon('sepa_debit')"/>
                    <div class="item-text">
                        Prélèvement SEPA
                    </div>
                </div>
            </div>
            <div class="vx-col sm:tw-w-1/2 tw-w-full">
                <div
                    :class="[
                        'item',
                        isModeSelected('card') ? 'item-selected' : '',
                        disabled ? 'item-disabled' : ''
                    ]"
                    @click="onModeChange('card')"
                    data-cy="card-mode-tab"
                >
                    <!-- <div class="item-circle"></div> -->
                    <img class="item-icon" :src="getModeIcon('card')"/>
                    <div class="item-text">
                        Carte bancaire
                    </div>
                </div>
            </div>
        </div>
         <div class="vx-row" v-if="firstOption==='card'">
            <div class="vx-col sm:tw-w-1/2 tw-w-full">
                <div
                    :class="[
                        'item',
                        isModeSelected('card') ? 'item-selected' : '',
                        disabled ? 'item-disabled' : ''
                    ]"
                    @click="onModeChange('card')"
                >
                    <!-- <div class="item-circle"></div> -->
                    <img class="item-icon" :src="getModeIcon('card')"/>
                    <div class="item-text">
                        Carte bancaire
                    </div>
                </div>
            </div>
            <div class="vx-col sm:tw-w-1/2 tw-w-full">
                <div
                    :class="[
                        'item',
                        isModeSelected('sepa_debit') ? 'item-selected' : '',
                        disabled ? 'item-disabled' : ''
                    ]"
                    @click="onModeChange('sepa_debit')"
                >
                    <!-- <div class="item-circle"></div> -->
                    <img class="item-icon" :src="getModeIcon('sepa_debit')"/>
                    <div class="item-text">
                        Prélèvement SEPA
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'PaymentModeSelector',
    props: [
        'value',
        'disabled',
        'firstOption'
    ],
    methods: {
        getModeIcon (mode) {
            const suffix = this.isModeSelected(mode) ? '-selected' : ''
            switch (mode) {
                case 'card':
                    return require(`@/assets/images/ehpad/card${suffix}.svg`)
                case 'sepa_debit':
                    return require(`@/assets/images/ehpad/sepa${suffix}.svg`)
            }
        },
        isModeSelected (mode) {
            return this.value == mode
        },
        onModeChange (mode) {
            if (this.disabled) {
                return
            }
            this.$emit('change', mode)
        }
    }
}
</script>

<style lang="scss">
$bp-mobile: 768px;
$bp-tablet: 768px;
$bp-desktop: 992px;

.payment-mode-selector {
    margin: 21px 0px 0px 0px;
    user-select: none;

    .item {
        display: flex;
        align-items: center;
        height: 54px;
        padding: 12px 12px 12px 12px;
        border: 1px solid rgba(var(--vs-primary), 1);
        border-radius: 8px;
        color: #2C2C2C;
        cursor: pointer;

        @media (max-width: $bp-mobile) {
          margin: 10px 0px 0px 0px;
        }

        .item-circle {
            width: 18px;
            height: 18px;
            margin: 0px 10px 0px 0px;
            border-radius: 20px;
            border: 1px solid #979797;
        }
        .item-icon {
            margin: 0px 10px 0px 0px;
        }
        .item-text {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: rgba(var(--vs-primary), 1);
        }
    }
    .item-selected {
        background: rgba(var(--vs-primary), 1);
        border: 1px solid rgba(var(--vs-primary), 1);
        box-shadow: -1px 1px 8px rgba(126, 114, 242, 0.2);
        color: white;

        .item-circle {
            border: 2px solid white;
        }
        .item-text {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: white;
        }
    }
    .item-disabled {
        cursor: default;
    }
}
</style>
