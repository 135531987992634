export default {
    FirstName: /[a-z]/i,
    LastName: /[a-z]/i,
    Birthday: /[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/,
    Phone: /^((\+)33|0)[1-9](\d{2}){4}$/,
    RoomNumber: /^[A-Z0-9-\s]{1,15}$/i,
    Email: /^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/,
    Minuscule: /[a-z]/,
    Majuscule: /[A-Z]/,
    AlphaNumeric: /^[A-Za-z0-9 ]+$/,
    Number: /\d/
}
