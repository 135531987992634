<template>
    <div id="resident-store-payment" class="data-list-container" v-show="stripeLoaded">
        <div class="vx-row page-row">
            <div class="vx-col left-col">

                <BackButton
                  label="Retour au choix de la livraison"
                  @click="onBackBtnClick"
                />
                <ResidentHeader
                  page-title="Paiement"
                  :displayResidentMenu="false"
                  class="mt-sm-0"
                />

                <div
                    class="payment-detail"
                    v-if="viewMode == 'setup' || viewMode == 'oneshot'"
                >
                    <div class="detail-header">
                        <div class="header-title">{{viewMode == 'setup' ? 'Enregistrez votre moyen de paiement' :'Renseignez vos informations de paiement'}}</div>
                        <div class="header-price"></div>
                    </div>
                    <div class="detail-content">
                        <div class="payment-label">
                            {{ (viewMode == 'setup' && isUserFamily ) ? "Pour valider vos abonnements, nous avons besoin d'enregistrer votre moyen de paiement afin de procéder aux commandes mensuelles." :''}}
                            <!-- {{ viewMode != 'oneshot' ? `Sélectionner un mode de paiement` : '' }} -->
                        </div>
                        <PaymentModeSelector
                            v-if="viewMode != 'oneshot'"
                            class="payment-selector"
                            :firstOption="isUserFamily ? 'card' : 'sepa_debit'"
                            :value="payment.mode.setup"
                            @change="onPaymentSetupModeChange"
                        />
                        <div class="payment-form">
                            <div class="form-label">Nom du titulaire</div>
                            <vs-input
                                autofocus
                                class="form-input input-name"
                                v-model="payment.name"
                                type="text"
                                placeholder="Titulaire du compte"
                            />
                            <div
                                class="form-field-error"
                                v-if="fieldErrors.name"
                            >{{ fieldErrors.name }}</div>
                            <div class="form-label">Email du titulaire</div>
                            <vs-input
                                autofocus
                                class="form-input input-email"
                                v-model="payment.email"
                                type="text"
                                placeholder="votre.email@mail.com"
                            />
                            <div
                                class="form-field-error"
                                v-if="fieldErrors.email"
                            >{{ fieldErrors.email }}</div>
                            <div class="field-iban" v-show="payment.mode.setup == 'sepa_debit'">
                                <div class="form-label">IBAN</div>
                                <div class="stripe-form-input input-iban iban-element"></div>
                                <div
                                    class="form-field-error"
                                    v-if="fieldErrors.iban"
                                >{{ fieldErrors.iban }}</div>
                            </div>
                            <div class="field-bic" v-if="0" v-show="payment.mode.setup == 'sepa_debit'">
                                <div class="form-label">BIC / SWIFT</div>
                                <vs-input
                                    class="form-input input-bic"
                                    v-model="payment.bic"
                                    type="text"
                                    placeholder="Numéro à XX chiffres"
                                />
                                <div
                                    class="form-field-error"
                                    v-if="fieldErrors.bic"
                                >{{ fieldErrors.bic }}</div>
                            </div>
                            <div class="field-card" v-show="payment.mode.setup == 'card'">
                                <div class="form-label">Carte de titulaire</div>
                                <div class="stripe-form-input input-card card-element"></div>
                                <div
                                    class="form-field-error"
                                    v-if="fieldErrors.card"
                                >{{ fieldErrors.card }}</div>
                            </div>
                            <div
                                class="form-check"
                                v-if="viewMode != 'oneshot'"
                            >
                                <div class="check-input">
                                    <vs-checkbox
                                        v-model="payment.save_consent"
                                    > <div class="check-label">
                                    Afin de faciliter les futurs paiements,
                                    j'accepte que mes informations de paiement
                                    (Carte Bancaire ou Prélèvement SEPA) soient
                                    conservées, de façon sécurisée, et utilisées
                                    pour les prochains paiements.
                                </div></vs-checkbox>
                                </div>
                            </div>
                            <div
                                class="form-actions"
                                v-if="viewMode != 'oneshot'"
                            >
                                <vs-button
                                    class="save-btn"
                                    :disabled="saveBtnDisabled"
                                    @click="onSaveBtnClick"
                                >Enregistrer</vs-button>
                            </div>

                        </div>
                    </div>
                </div>


                <div class="payment-detail" v-if="viewMode == 'ready' && isDraftHasOnce">
                    <div class="detail-header">
                        <div class="header-title">A régler dès maintenant</div>
                        <div class="header-price">{{totalAmountToPay}}€</div>
                    </div>
                    <div class="detail-content">
                        <div class="payment-sepa" v-if="getResidentPaymentType() == 'sepa_debit'">
                            <div class="sepa-header">
                                <vs-radio
                                    class="sepa-radio"
                                    v-model="payment.mode.once"
                                    vs-name="mode"
                                    vs-value="sepa_debit"
                                    v-if="isUserFamily"
                                ></vs-radio>
                                <img class="sepa-icon" src="@/assets/images/ehpad/sepa.svg" />
                                <div class="sepa-title">
                                    <b>Utiliser le moyen de paiement enregistré du résident</b> (Prélèvement SEPA)
                                </div>
                            </div>
                            <div class="sepa-content">
                                <div class="sepa-name">{{ residentPayment.name }}</div>
                                <div class="sepa-number">{{ residentPayment.bankCode }}*************{{ residentPayment.last4 }}</div>
                            </div>
                            <div class="sepa-legend">
                                Le prélèvement ne sera effectué qu'une fois votre commande acceptée par le(s) vendeur(s).
                            </div>
                        </div>
                        <div class="payment-card" v-if="getResidentPaymentType() == 'card'">
                            <div class="card-header">
                                <vs-radio
                                    class="card-radio"
                                    v-model="payment.mode.once"
                                    vs-name="mode"
                                    vs-value="card"
                                    v-if="isUserFamily"
                                ></vs-radio>
                                <img class="card-icon" src="@/assets/images/ehpad/card.svg" />
                                <div class="card-title">
                                    <b>Utiliser le moyen de paiement enregistré du résident</b> (Prélèvement CB)
                                </div>
                            </div>
                            <div class="card-content">
                                <div class="card-name">{{ residentPayment.name }}</div>
                                <div class="card-number">**** **** **** {{ residentPayment.last4 }}</div>
                                <div class="card-number">Valide jusque : {{ residentPayment.expirationMonth }}/{{ residentPayment.expirationYear }}</div>
                            </div>
                            <div class="card-legend">
                                Le prélèvement ne sera effectué qu'une fois votre commande acceptée par le(s) vendeur(s).
                            </div>
                        </div>
                        <div class="payment-card" v-if="isUserFamily">
                            <div class="card-header">
                                <vs-radio
                                    class="card-radio"
                                    v-model="payment.mode.once"
                                    vs-name="mode"
                                    vs-value="other"
                                ></vs-radio>
                                <img class="card-icon" src="@/assets/images/ehpad/card.svg" />
                                <div class="card-title">
                                    <b>Utiliser une autre carte pour cette fois</b>
                                </div>
                            </div>
                            <div class="card-content" v-show="payment.mode.once == 'other'">
                                <div class="payment-form">
                                    <div class="form-label">Nom du titulaire</div>
                                    <vs-input
                                        autofocus
                                        class="form-input name-single"
                                        v-model="payment.name"
                                        type="text"
                                        placeholder="Titulaire du compte"
                                    />
                                    <div
                                        class="form-field-error"
                                        v-if="fieldErrors.name"
                                    >{{ fieldErrors.name }}</div>
                                    <div class="form-label">Email du titulaire</div>
                                    <vs-input
                                        autofocus
                                        class="form-input email-single"
                                        v-model="payment.email"
                                        type="text"
                                        placeholder="votre.email@mail.com"
                                    />
                                    <div
                                        class="form-field-error"
                                        v-if="fieldErrors.email"
                                    >{{ fieldErrors.email }}</div>
                                    <div class="field-card">
                                        <div class="form-label">Carte de titulaire</div>
                                        <div class="stripe-form-input card-single"></div>
                                        <div
                                            class="form-field-error"
                                            v-if="fieldErrors.card"
                                        >{{ fieldErrors.card }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    class="payment-detail"
                    v-if="viewMode == 'ready' && isDraftHasSubscription"
                >
                    <div class="detail-header">
                        <div class="header-title">Prélèvement mensuel</div>
                        <!-- <div class="header-price">{{getDraftAmount('subscription')}}€<br>
                            <span v-if="nextOrderEstimatedAmount" class="header-price-detail">Soit un total pour la commande du 1er {{nextMonth}}  de {{ nextOrderEstimatedAmount }}€ (hors frais de livraison éventuels)</span>
                            <span v-else class="header-price-detail">Votre première commande (1er {{nextMonth}}) sera donc de {{getDraftAmount('subscription')}}€ (hors frais de livraison éventuels)</span>
                        </div> -->
                        <div class="header-price-subscription">
                            <div  v-for="(frequencyAmount, frequency) in subscriptionAmountByFrequency" :key="frequency">
                                {{ formatFrequency(frequency) }} : {{ rounded(frequencyAmount) }}€<br/><br/>
                            </div>
                        </div>
                    </div>
                    <div class="detail-content">
                        <div class="payment-sepa" v-if="getResidentPaymentType() == 'sepa_debit'">
                            <div class="sepa-header">
                                <img class="sepa-icon" src="@/assets/images/ehpad/sepa.svg" />
                                <div class="sepa-title">
                                   Le paiement mensuel de vos abonnements sera automatiquement effectué par prélèvement (SEPA) sur votre moyen de paiement enregistré
                                </div>
                            </div>
                            <div class="sepa-content">
                                <div class="sepa-name">{{ residentPayment.name }}</div>
                                <div class="sepa-number">{{ residentPayment.bankCode }}*************{{ residentPayment.last4 }}</div>
                            </div>
                            <div class="sepa-legend">
                                Le prélèvement mensuel n'est effectué qu'une fois les commandes envoyées et les sevices effectués par le(s) vendeur(s) : <strong>votre prochain prélèvement aura donc lieu courant {{nextMonth}}</strong>
                            </div>
                        </div>
                        <div class="payment-card" v-if="getResidentPaymentType() == 'card'">
                            <div class="card-header">
                                <img class="card-icon" src="@/assets/images/ehpad/card.svg" />
                                <div class="card-title">
                                    Le paiement mensuel de vos abonnements sera automatiquement effectué par prélèvement (CB) sur votre moyen de paiement enregistré
                                </div>
                            </div>
                            <div class="card-content">
                                <div class="card-name">{{ residentPayment.name }}</div>
                                <div class="card-number">**** **** **** {{ residentPayment.last4 }}</div>
                                <div class="card-number">Valide jusque : {{ residentPayment.expirationMonth }}/{{ residentPayment.expirationYear }}</div>
                            </div>
                            <div class="card-legend">
                                Le prélèvement mensuel n'est effectué qu'une fois les commandes envoyées et les sevices effectués par le(s) vendeur(s) : <strong>votre prochain prélèvement aura donc lieu courant {{nextMonth}}</strong>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="vx-col right-col">
              <BasketSummary
                v-if="loaded"
                class="basket-summary"
                :toasted="isBasketSummaryToasted"
                :once-quantity="getProductCount('once')"
                :once-amount="totalOnce"
                :subscription-quantity="getProductCount('subscription')"
                :subscription-amount="getDraftAmount('subscription')"
                :subscription-amount-by-frequency="subscriptionAmountByFrequency"
                show-total
                :once-total="totalOnce + shippingOnce"
                show-shipping-info
                :shipping-name="getResidentName()"
                :shipping-address="institution"
                :shipping-fees="shippingOnce"
                :show-cgv-consent="viewMode == 'ready' || viewMode == 'oneshot'"
                :cgv-consent.sync="payment.cgv_consent"
                :next-label ="getProductCount('once') > 0 ? 'Valider et payer': 'Valider'"
                :next-disabled="validateAndPayDisabled"
                @next="onFinalBtnClick"
              />
            </div>
            <hx-popup
                :buttonCloseHidden="true"
                :modal="true"
                title="Commande validée"
                :active.sync="displayFinalPopin"
            >
                <p>{{confirmationMessage}}</p>
                <br/>
                <vs-button
                    class="save-btn"
                    @click="acceptAction"
                >{{acceptText}}</vs-button>
            </hx-popup>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import async from 'async'
import uuid from 'uuid/v4'
import Http from '@/http'

import Routes from '@/constants/routes'
import Regexes from '@/constants/regexes'
import { getFrequencyLabel } from '@/modules/labels'
import { getRoundedPrice } from '@/modules/numbers'
import { getNextMonth } from '@/modules/date'

import {
  computeBasketTotal,
  computeBasketShipping
} from '@/modules/services'

import BackButton from '@/components/BackButton'
import ResidentHeader from '@/views/residents/components/ResidentHeader.vue'
import PaymentModeSelector from '@/components/selectors/PaymentModeSelector'
import BasketSummary from '@/components/BasketSummary'

import appsettings from '@/appsettings'
import Oauth from "@/oauth";

export default {
    name: 'StorePayment',
    props: [
        'resident_id'
    ],
    components: {
        BackButton,
        PaymentModeSelector,
        BasketSummary,
        ResidentHeader
    },
    data () {
        return {
            loaded: false,
            stripeLoaded: false,
            residentPayment: null,
            payment: {
                secret: null,
                mode: {
                    setup: null,
                    once: 'default'
                },
                name: null,
                email: null,
                iban: null,
                bic: null,
                card: null,
                cgv_consent: false,
                save_consent: false
            },
            viewMode: null,
            iban: null,
            ibanValid: false,
            card: null,
            cardValid: false,
            singlePaymentCard: null,
            singlePaymentCardValid: false,
            isDraftHasOnce: false,
            isDraftHasSubscription: false,
            isUserReferant: false,
            fieldErrors: {},
            saveBtnDisabled: true,
            validateAndPayDisabled: true,
            displayFinalPopin: false,
            confirmationMessage: '',
            acceptText: '',
            acceptAction: this.onGoToHistoryBtnClick,
            paymentIntentIdempotencyKey: uuid(),
            orderIdempotencyKey: uuid()
        }
    },
    computed: {
        institutionId () {
            return this.$store.getters['dataUser/getInstitutionId']()
        },
        userId () {
            return this.$store.getters['dataUser/getUserId']()
        },
        isUserFamily () {
            return this.$aclChecker.some(this.$acl, [
                'isFamily'
            ])
        },
        isUserTutor () {
            return this.$aclChecker.some(this.$acl, [
                'isTutor',
                'isHappytalSupport',
                'isHappytalEmployee'
            ])
        },
        currentBasket() {
            return this.$store.getters['dataMultiBasket/getBasketByResidentId'](this.resident_id)
        },
        totalAmountToPay() {
            const quote = this.currentBasket.quoteContent
            return Number((quote.totalAmountProduct + quote.totalAmountService).toFixed(2))
        },
        institution() {
            const institutionId = this.getInstitutionId()
            return this.$store.getters['dataInstitutions/getInstitutionById'](institutionId)
        },
        totalOnce() {
            return computeBasketTotal(this.currentBasket)
        },
        shippingOnce() {
            return computeBasketShipping(this.currentBasket)
        },
        currentPcp () {
            return this.$store.getters['dataPcps/getResidentPcp'](this.resident_id)
        },
        currentResident () {
            return this.$store.getters['dataResidents/getResidentById'](this.resident_id)
        },
        nextOrder () {
            if (this.currentResident && this.currentResident.currentBasket) {
                return this.$store.getters['dataBaskets/getBasketById'](this.currentResident.currentBasket.basketId)
            }
            return undefined
        },
        nextMonth () {
            return getNextMonth()
        },
        subscriptionAmountByFrequency() {
            return this.$store.getters['dataDrafts/getSubscriptionAmountByFrequency'](this.getUserId(), this.resident_id)
        },
        isBasketSummaryToasted () {
          return this.$store.state.windowWidth < 768
        }
    },
    methods: {
        getUserId() {
            return this.$store.getters['dataUser/getUserId']()
        },
        getInstitutionId () {
            return this.$store.getters['dataUser/getInstitutionId']()
        },
        getResidentById (residentId) {
            return this.$store.getters['dataResidents/getResidentById'](residentId)
        },
        getResidentName () {
            return this.$store.getters['dataResidents/getResidentName'](this.resident_id)
        },
        getFirstDraft (userId, residentId, draftType) {
            return this.$store.getters['dataDrafts/getFirstDraft'](userId, residentId, draftType)
        },
        getCurrentDraft (draftType) {
            return this.getFirstDraft(this.getUserId(), this.resident_id, draftType)
        },
        getProducts (draftType, productType) {
            return this.$store.getters['dataDrafts/getProducts'](this.getUserId(), this.resident_id, draftType, productType)
        },
        getProductCount (draftType, productType) {
            return this.$store.getters['dataDrafts/getProductCount'](this.getUserId(), this.resident_id, draftType, productType)
        },
        getDraftAmount (draftType, productType) {
            return this.$store.getters['dataDrafts/getDraftAmount'](this.getUserId(), this.resident_id, draftType, productType)
        },
        formatFrequency(frequency) {
            return getFrequencyLabel(parseInt(frequency))
        },
        rounded(price) {
            return getRoundedPrice(price)
        },
        isDraftEmpty (draftType = null) {
            return this.getProductCount(draftType) < 1
        },
        getResidentPaymentData (resident) {
            if (resident && resident.stripePaymentMethod) {
                return resident.stripePaymentMethod
            }
            return null
        },
        getResidentPaymentType () {
            return _.get(this.residentPayment, 'type', null)
        },
        onBackBtnClick (e) {
            // if abonnement only, go back to basket
            if (!this.isDraftHasOnce && this.isDraftHasSubscription)
            this.$router.push({
                name: Routes.ResidentStoreBasket,
                params: {
                    resident_id: this.resident_id
                }
            })
            else
            this.$router.push({
                name: Routes.ResidentStoreShipping,
                params: {
                    resident_id: this.resident_id
                }
            })
        },
        onGoToNextOrderBtnClick (e) {
            this.$router.push({
                name: Routes.ResidentSubscriptionsNext,
                params: {
                    resident_id: this.resident_id
                }
            })
        },
        onGoToHistoryBtnClick (e) {
             this.$router.push({
                name: Routes.ResidentHistory,
                params: {
                    resident_id: this.resident_id
                }
            })
        },
        onPaymentSetupModeChange (mode) {
            this.payment.mode.setup = mode
            this.payment.mode.once = mode
        },
        onSaveBtnClick (e) {
            this.$vs.loading()
            Http.apis.ehpad.get(`/residents/${this.resident_id}/payment-method-setup-intent`)
            .then(
                (response) => {
                    this.payment.secret = response.data.setupIntentClientSecret
                    switch (this.payment.mode.setup) {
                        case 'sepa_debit':
                            this.stripe.confirmSepaDebitSetup(this.payment.secret, {
                                payment_method: {
                                    sepa_debit: this.iban,
                                    billing_details: {
                                        name: this.payment.name,
                                        email: this.payment.email
                                    }
                                }
                            })
                            .then(this.onStripeSetup.bind(this))
                            break
                        case 'card':
                            this.stripe.confirmCardSetup(this.payment.secret, {
                                payment_method: {
                                    card: this.card,
                                    billing_details: {
                                        name: this.payment.name,
                                        email: this.payment.email
                                    }
                                }
                            })
                            .then(this.onStripeSetup.bind(this))
                            break
                    }
                },
                (error) => {
                    this.$vs.loading.close()
                    this.showErrorToast(this.getGeneralErrorMessage(error))
                }
            )
        },
        onStripeSetup (result) {
            this.$vs.loading.close()
            if (result.error) {
                const message = _.get(result, 'error.message', '')
                this.showErrorToast(this.getStripeErrorMessage(message))
                return
            }
            Http.apis.ehpad.post(`/residents/${this.resident_id}/payment-method`, {
                stripePaymentMethodId: result.setupIntent.payment_method
            })
            .then(
                (response) => {
                    this.residentPayment = _.get(response, 'data.stripePaymentMethod', null)
                    this.$store.commit('dataResidents/UPDATE_PAYMENT', {
                        residentId: this.resident_id,
                        payment: this.residentPayment
                    })
                    this.showInfoToast(`Moyen de paiement enregistré.`)
                    this.viewMode = 'ready'
                    if (this.isUserFamily) {
                        //Inject the stripe single payment card
                        this.$nextTick(() => {
                            this.initStripe(false,false ,true)
                        })
                    }
                },
                (error) => {
                    this.showErrorToast(this.getGeneralErrorMessage(error))
                }
            )
        },
        showErrorToast (text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration : null,
                action : [
                    {
                        text : 'Fermer',
                        onClick : (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        },
        async onFinalBtnClick (e) {
            this.$vs.loading()
            if (this.getDraftAmount('subscription') > 0) {
                const draftProducts = this.getProducts('subscription', 'product')
                const draftServices = this.getProducts('subscription', 'service')
                try {
                    await this.$store.dispatch('dataPcps/mergePcp', {
                        residentId: this.resident_id,
                        pcp: this.currentPcp,
                        products: draftProducts,
                        services: draftServices
                    })
                }
                catch (err) {
                    console.error(err)
                    this.showErrorToast(`Impossible de sauvegarder l'abonnement: ${err.message}`)
                }
            }
            if( this.getDraftAmount('once') > 0 ) {
                if (this.payment.mode.once == 'other' || this.viewMode == 'oneshot') {
                    try {
                        const response = await Http.apis.ehpad.post(`/payment-intents`, {
                            basketId: this.currentBasket.id,
                            amountToPay: this.totalAmountToPay,
                            userId: this.currentResident.userInChargeOfResident.id ,
                            idempotencyKey: this.paymentIntentIdempotencyKey
                        })
                        var stripePaymentIntentClientSecret = response.data.paymentIntentClientSecret
                        var paymentIntentId = response.data.paymentIntentId
                        const resultOrder = await Http.apis.ehpad.post(`/orders`, {
                            basketId: this.currentBasket.id,
                            amount: this.totalAmountToPay,
                            userId: this.currentResident.userInChargeOfResident.id,
                            residentId: this.resident_id,
                            paymentIntentId: paymentIntentId,
                            quoteId: this.currentBasket.quoteId,
                            idempotencyKey: this.orderIdempotencyKey
                        })
                        const stripeResult = await this.stripe.confirmCardPayment(
                            stripePaymentIntentClientSecret, {
                                payment_method: {
                                    card: this.viewMode == 'oneshot' ? this.card : this.singlePaymentCard,
                                }
                            },
                            // {handleActions: false}
                        )
                        if (stripeResult.error) {
                            console.log(stripeResult.error)
                            this.showErrorToast("Le paiement n'a pas fonctionné, merci de vérifier vos informations de carte bancaire et de tenter à nouveau.")
                        }
                        else {
                            this.$router.push({
                                name: Routes.ResidentStoreThankYou,
                                params: {
                                    resident_id: this.resident_id,
                                    order: resultOrder.data.commercialId
                                }
                            })
                        }
                    }
                    catch (error) {
                        this.showErrorToast(this.getGeneralErrorMessage(error))
                    }
                    finally {
                        this.$vs.loading.close()
                    }
                }
                else {
                        try {
                            var recordedPayment = this.getResidentPaymentData(this.getResidentById(this.resident_id))
                            const resultOrder = await Http.apis.ehpad.post(`/orders`, {
                                basketId: this.currentBasket.id,
                                amount: this.totalAmountToPay,
                                quoteId: this.currentBasket.quoteId,
                                userId: this.currentResident.userInChargeOfResident.id,
                                residentId: this.resident_id,
                                paymentMethodId: recordedPayment.id,
                                idempotencyKey: this.orderIdempotencyKey,
                            })
                            this.$router.push({
                                name: Routes.ResidentStoreThankYou,
                                params: {
                                    resident_id: this.resident_id,
                                    order: resultOrder.data.commercialId
                                }
                            })
                            this.$vs.loading.close()
                        }
                        catch (error) {
                            this.$vs.loading.close()
                            this.showErrorToast(this.getGeneralErrorMessage(error))
                        }
                }
            }
            else {
                this.$router.push({
                    name: Routes.ResidentStoreThankYou,
                    params: {
                        resident_id: this.resident_id,
                    }
                })
            }
        },
        checkFields () {
            switch (this.viewMode) {
                case 'setup':
                  switch (this.payment.mode.setup) {
                    case 'sepa_debit':
                      if (!this.ibanValid) {
                        this.saveBtnDisabled = true
                        return
                      }
                      break
                    case 'card':
                      if (!this.cardValid) {
                        this.saveBtnDisabled = true
                        return
                      }
                      break
                  }
                  this.saveBtnDisabled = !this.payment.name || (this.payment.email && !Regexes.Email.test(this.payment.email)) || !this.payment.save_consent
                  break
                case 'ready':
                  this.validateAndPayDisabled = !this.payment.cgv_consent || (this.payment.mode.once == 'other' && !this.singlePaymentCardValid)
                  break
                case 'oneshot':
                  this.validateAndPayDisabled = !this.payment.cgv_consent || !this.cardValid
                  break
            }
        },
        getGeneralErrorMessage (error) {
            return `Une erreur s'est produite (${error}).`
        },
        getStripeErrorMessage (error) {
            return `Une erreur Stripe s'est produite (${error}).`
        },
        initStripe (iban, card, singlePaymentCard) {
            if (this.card) {
              this.card.destroy()
            }
            this.stripe = Stripe(appsettings.VUE_APP_STRIPE_ID)
            var elements = this.stripe.elements()
            var style = {
                base: {
                    color: '#32325d',
                    fontSize: '16px',
                    '::placeholder': {
                        color: '#aab7c4'
                    },
                    ':-webkit-autofill': {
                        color: '#32325d'
                    }
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a',
                    ':-webkit-autofill': {
                        color: '#fa755a'
                    }
                }
            }
            if (iban) {
                this.iban = elements.create('iban', {
                    style: style,
                    supportedCountries: ['SEPA'],
                    placeholderCountry: 'FR'
                })
                this.iban.mount('.iban-element')
                this.iban.on('change', (stripeEvent) => {
                    this.ibanValid = stripeEvent.complete ? true : false
                    this.checkFields()
                })
                this.iban.on('ready', (stripeEvent) => {
                    this.stripeLoaded = true
                    this.$vs.loading.close()
                })
            }
            if (card) {
                this.card = elements.create('card', {
                    style,
                    hidePostalCode: true,
                })
                this.card.mount('.card-element')
                this.card.on('change', (stripeEvent) => {
                    this.cardValid = stripeEvent.complete ? true : false
                    this.checkFields()
                })
                this.card.on('ready', (stripeEvent) => {
                    this.stripeLoaded = true
                    this.$vs.loading.close()
                })
            }
            if (singlePaymentCard) {
                this.singlePaymentCard = elements.create('card', {
                    style,
                    hidePostalCode: true,
                })
                this.singlePaymentCard.mount('.card-single')
                this.singlePaymentCard.on('change', (stripeEvent) => {
                    this.singlePaymentCardValid = stripeEvent.complete ? true : false
                    this.checkFields()
                })
                this.singlePaymentCard.on('ready', (stripeEvent) => {
                    this.stripeLoaded = true
                    this.$vs.loading.close()
                })
            }
        },
        showInfoToast (text) {
            this.$toasted.show(text, {
                theme: 'hx-toasted',
                position: 'hx-bottom-left',
                duration: 5000,
                action: [
                    {
                        text: 'Fermer',
                        onClick: (e, to) => {
                            to.goAway(0)
                        }
                    }
                ]
            })
        }
    },
    watch: {
        'payment.name': function (val) {
            this.checkFields()
        },
        'payment.email': function (val) {
            this.checkFields()
        },
        'payment.cgv_consent': function (val) {
            this.checkFields()
        },
        'payment.save_consent': function (val) {
            this.checkFields()
        },
        'payment.mode.once': function (val) {
            this.checkFields()
        }
    },
    async mounted () {
        this.$vs.loading.close()
        this.loaded = false
        this.$store.dispatch('dataPcps/getResidentPcp', {
            residentId: this.resident_id
        })
        this.$store.dispatch('dataResidents/getResidents', {
            userId: this.userId,
            institutionId: this.institutionId
        })
        .then(async () => {
            if (this.currentResident.currentBasket) {
                try {
                    await this.$store.dispatch('dataBaskets/getBasket', {
                        basketId: this.currentResident.currentBasket.basketId
                    })
                }
                catch(err) {
                    // Resident does not have a basket yet, ignore.
                    console.error(err)
                }
            }
        })
        async.series([
                (callback) => {
                    if (!this.$store.state.dataResidents.isRegistered) {
                        this.$store.dispatch('dataResidents/getResidents', {
                            userId: this.getUserId(),
                            institutionId: this.getInstitutionId()
                        })
                        .then(
                            () => {

                                callback(null)
                            },
                            (error) => {
                                this.showErrorToast(this.getGeneralErrorMessage(error))
                                callback(true)
                            }
                        )
                        return
                    }
                    callback(null)
                }
            ],
            (err, results) => {
                if (err) {
                    return
                }
                Oauth.checkUserRight(this.$store.getters['dataResidents/getResidentById'](this.resident_id), this.$router)
                this.resident = this.getResidentById(this.resident_id)
                this.residentPayment = this.getResidentPaymentData(this.resident)
                this.isDraftHasOnce = !this.isDraftEmpty('once')
                this.isDraftHasSubscription = !this.isDraftEmpty('subscription')
                if (this.residentPayment) {
                    //console.log('case A - MDP already filled : ' + JSON.stringify(this.residentPayment) )
                    this.viewMode = 'ready'
                    this.payment.mode = {
                        setup: this.residentPayment.type,
                        once: this.residentPayment.type
                    }
                }
                else if (this.isUserTutor || this.isUserFamily) {
                    //console.log('case B - No MDP filled')
                    //this.viewMode = isTutor ? 'setup' : 'oneshot'
                    if (this.isUserTutor) {
                        //console.log('case B1 - Is Tutor / need to force setup of Sepa')
                        this.viewMode = 'setup'
                        this.payment.mode = {
                            setup: 'sepa_debit',
                            once: 'sepa_debit'
                        }
                    }
                    else {
                        //console.log('case B2 - Is Family - if subscription, needs setup')
                        this.viewMode = this.isDraftHasSubscription ? 'setup' : 'oneshot'
                        this.payment.mode = {
                            setup: 'card',
                            once: this.isDraftHasOnce ? 'card' : null
                        }
                    }
                }
                // else if (this.isDraftHasSubscription) {
                //     console.log('case C')
                //     this.viewMode = 'setup'
                //     this.payment.mode = {
                //         setup: 'card',
                //         once: 'card'
                //     }
                // }
                // else {
                //     console.log('case D')
                //     this.viewMode = 'setup'
                //     this.payment.mode = {
                //         setup: 'sepa',
                //         once: 'sepa'
                //     }
                //   }
                //console.log('viewMode', this.viewMode)
                this.$vs.loading()
                setTimeout(() => {
                        switch (this.viewMode) {
                            case 'setup':
                                this.initStripe(true, true, false)
                                break
                            case 'ready':
                                if (this.isUserFamily)
                                    this.initStripe(false, false, true)
                                else
                                {
                                    this.$vs.loading.close()
                                    this.stripeLoaded=true
                                }
                                break;
                            case 'oneshot':
                                this.initStripe(false, true, false)
                                break
                        }
                    },
                    1000
                )
                this.loaded = true
            }
        )
    }
}
</script>

<style lang="scss">
@import '~@happytal/bo-ui-library/src/styles/variables';

#resident-store-payment {

    .form-check {
        display: flex;

        @media (max-width: map-get($breakpoints, sm)) {
          margin: 15px 0px 0px 0px;
        }

       .check-input {
            display: flex;

            .vs-checkbox {
                flex-shrink: 0;
                margin-right: 10px;
            }
        }
    }

    .payment-title {
        margin: 0px 0px 26px 0px;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #2C2C2C;
    }

    .payment-detail {
        margin: 0px 0px 42px 0px;
        border: 2px solid #D8D8D8;
        border-radius: 8px;
        font-family: Montserrat;
        color: #2C2C2C;

        .detail-header {
            display: flex;
            align-items: center;
            padding: 22px 22px 22px 22px;

            .header-title {
                flex-grow: 1;
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
            }
            .header-price {
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 22px;
                text-align: right;
            }
            .header-price-subscription {
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 13px;
                text-align: right;
            }
            .header-price-detail {
                font-style: italic;
                font-weight: normal;
                font-size: 12px;
            }
        }
        .detail-content:last-child {
            margin: 0px 0px 0px 0px;
        }
        .detail-content {
            margin: 0px 0px 37px 0px;
            box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.135216);
            border-radius: 8px;
            background: white;

            .payment-label {
                padding: 10px 0px 10px 0px;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                text-align: center;
            }
            .payment-selector {
                margin: 0px 24px 0px 24px;
            }
            .payment-custom {
                padding: 10px 0px 10px 0px;
                border-bottom: 1px solid #D8D8D8;

                .label {
                    max-width: 568px;
                    margin: 0px auto 0px auto;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 18px;
                    text-align: center;
                }
            }
        }
    }

    .payment-form {
        padding: 0px 24px 24px 24px;

        .form-label {
            margin: 20px 0px 0px 0px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #2C2C2C;
        }
        .form-input {
            margin: 5px 0px 0px 0px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #2C2C2C;
        }
        .stripe-form-input {
            margin: 5px 0px 0px 0px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            padding: 7px;
            border-radius: 5px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
            color: #2C2C2C;
        }
        .form-field-error {

        }
        .input-name {

            .vs-con-input {

                @media (min-width: map-get($breakpoints, sm)) {
                  width: 460px;
                }
                @media (max-width: map-get($breakpoints, sm)) {
                  width: 300px;
                }
            }
        }
        .input-email {

            .vs-con-input {

                @media (min-width: map-get($breakpoints, sm)) {
                  width: 460px;
                }
                @media (max-width: map-get($breakpoints, sm)) {
                  width: 300px;
                }
            }
        }
        .input-iban {

            @media (min-width: map-get($breakpoints, sm)) {
              width: 647px;
            }
            @media (max-width: map-get($breakpoints, sm)) {

            }
        }
        .input-bic {

            .vs-con-input {

                @media (min-width: map-get($breakpoints, sm)) {
                  width: 460px;
                }
                @media (max-width: map-get($breakpoints, sm)) {
                  width: 460px;
                }
            }
        }
        .input-card {

            @media (min-width: map-get($breakpoints, sm)) {
              width: 460px;
            }
            @media (max-width: map-get($breakpoints, sm)) {
              width: 300px;
            }
        }
        .name-single {

            .vs-con-input {

                @media (min-width: map-get($breakpoints, sm)) {
                  width: 460px;
                }
                @media (max-width: map-get($breakpoints, sm)) {
                  width: 260px;
                }
            }
        }
        .email-single {

            .vs-con-input {

                @media (min-width: map-get($breakpoints, sm)) {
                  width: 460px;
                }
                @media (max-width: map-get($breakpoints, sm)) {
                  width: 260px;
                }
            }
        }
        .card-single {

            @media (min-width: map-get($breakpoints, sm)) {
              width: 460px;
            }
            @media (max-width: map-get($breakpoints, sm)) {
              width: 260px;
            }
        }
        .form-actions {

            @media (min-width: map-get($breakpoints, sm)) {
              text-align: right;
            }
            @media (max-width: map-get($breakpoints, sm)) {
              text-align: center;
            }

            .save-btn {
                width: 273px;
                margin: 18px 0px 0px 0px;
            }
        }
    }

    .payment-sepa {
        padding: 22px 25px 22px 25px;

        .sepa-header {
            display: flex;
            align-items: center;

            .sepa-radio {
                margin: 0px 16px 0px 0px;
            }
            .sepa-icon {

            }
            .sepa-title {
                margin: 0px 0px 0px 16px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                color: #2C2C2C;
            }
        }
        .sepa-content {
            display: flex;
            margin: 14px 0px 0px 0px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #979797;

            .sepa-name {
                flex-grow: 1;
                text-align: center;
            }
            .sepa-number {
                flex-grow: 1;
                text-align: center;
            }
            .sepa-bic {
                flex-grow: 1;
                text-align: center;
            }
        }
        .sepa-legend {
            margin: 20px 0px 10px 0px;
            font-style: italic;
            font-weight: normal;
            font-size: 12px;
            line-height: 12px;
            text-align: center;
            color: #2C2C2C;
        }
    }

    .payment-card {
        padding: 22px 25px 22px 25px;

        .card-header {
            display: flex;
            align-items: center;

            .card-radio {
                margin: 0px 16px 0px 0px;
            }
            .card-icon {

            }
            .card-title {
                margin: 0px 0px 0px 16px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 19px;
                color: #2C2C2C;
            }
        }
        .card-content {
            display: flex;
            margin: 14px 0px 0px 0px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
            color: #979797;

            .card-name {
                flex-grow: 1;
                text-align: center;
            }
            .card-number {
                flex-grow: 1;
                text-align: center;
            }
        }
        .card-legend {
            margin: 20px 0px 10px 0px;
            font-style: italic;
            font-weight: normal;
            font-size: 12px;
            line-height: 12px;
            text-align: center;
            color: #2C2C2C;
        }
    }

    @media (max-width: map-get($breakpoints, sm)) {
      .page-row {
          flex-wrap: wrap;
      }
      .left-col {
          flex-grow: 1;
      }
      .right-col {
          flex-grow: 1;

          .basket-summary {
              position: relative;
          }
      }
    }
    @media (min-width: map-get($breakpoints, sm)) {
      .page-row {
          flex-wrap: nowrap;
      }
      .left-col {
          flex-grow: 1;
      }
      .right-col {
          flex-shrink: 0;
          width: 380px;

          .basket-summary {
              position: fixed;
              width: 350px;
          }
      }
    }
    @media (min-width: map-get($breakpoints, md)) {
      .page-row {
          flex-wrap: nowrap;
      }
      .left-col {
          flex-grow: 1;
      }
      .right-col {
          flex-shrink: 0;
          flex-basis: 480px;

          .basket-summary {
              position: fixed;
              width: 450px;
          }
      }
    }
}
</style>
